import { template as template_c24d5e8f54fc456b93246a5b3d727b6d } from "@ember/template-compiler";
const FKLabel = template_c24d5e8f54fc456b93246a5b3d727b6d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
