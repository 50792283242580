import { template as template_eeedb532419d4df0a2a691ecd3842813 } from "@ember/template-compiler";
const FKText = template_eeedb532419d4df0a2a691ecd3842813(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
